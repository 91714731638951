import { FC, FormEvent, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Alert, Container, Form, FormGroup, Input, Label } from "reactstrap";
import { isEmpty, isNull } from "lodash";
import axios from "axios";
import styled from "styled-components/macro";
import { Button } from "../components/button";

export const EnterpriseRegistration: FC = () => {
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);
  const state = useMemo(() => {
    const stateValue = params.get("state");

    if (isNull(stateValue) || isEmpty(stateValue)) {
      window.location.href = "/error";
      return "";
    }

    return stateValue;
  }, [params]);
  const installation = useMemo(() => {
    const installationValue = params.get("installation");

    if (installationValue == null) {
      window.location.href = "/error";
      return "";
    }

    return installationValue;
  }, [params]);
  const stateObject = useMemo<StateObject>(
    () => (isEmpty(state) ? {} : JSON.parse(window.atob(state))),
    [search]
  );
  const [submitted, setSubmitted] = useState(false);
  const [formInvalid, setFormInvalid] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    appId: "",
    privateKey: "",
  });
  const [error, setError] = useState<string>();

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setError(undefined);

      if (Object.values(formData).some((value) => isEmpty(value))) {
        setFormInvalid(true);
        return;
      }

      setSubmitted(true);

      try {
        await sendInstallationData(formData, state, installation);
        window.location.href = stateObject.redirectUrl;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError(
            (error.response?.data as string) ||
              "Something went wrong, please try again"
          );
        } else {
          setError("Something went wrong, please try again");
        }
      } finally {
        setSubmitted(false);
      }
    },
    [formData, state, installation]
  );

  return (
    <Container>
      <h2>GitHub Enterprise App Registration</h2>

      {error != null && <Alert color="danger">{error}</Alert>}

      <div>
        Please fill the following fields to connect to {stateObject.githubUrl}
      </div>

      <Form onSubmit={onSubmit} inline>
        <FormGroup>
          <Label for="app-id">App ID</Label>
          <Input
            id="app-id"
            type="text"
            onChange={(event) =>
              setFormData({
                ...formData,
                appId: event.target.value,
              })
            }
            invalid={formInvalid && formData.appId === ""}
          />
        </FormGroup>

        <FormGroup>
          <Label for="private-key">Private Key</Label>
          <Input
            id="private-key"
            type="file"
            onChange={(event) =>
              event.target.files?.[0].text().then((privateKey) =>
                setFormData({
                  ...formData,
                  privateKey,
                })
              )
            }
            invalid={formInvalid && formData.privateKey === ""}
          />
        </FormGroup>

        <Buttons>
          <Button disabled={submitted} color="success">
            Submit
          </Button>
          <Button
            disabled={submitted}
            type="button"
            secondary
            onClick={() => (window.location.href = stateObject.redirectUrl)}
          >
            Cancel
          </Button>
        </Buttons>
      </Form>
    </Container>
  );
};

const Buttons = styled.div`
  display: flex;
  flex-direction: row-reverse;

  & > * {
    margin-right: 5px;
  }
`;

interface FormData {
  appId: string;
  privateKey: string;
}

interface StateObject {
  apiiroToken: string;
  apiiroUrl: string;
  githubUrl?: string;
  redirectUrl: string;
}

async function sendInstallationData(
  data: FormData,
  state: string,
  installation: string
) {
  await axios.post("/api/registration/enterprise", {
    ...data,
    state,
    installation,
  });
}
