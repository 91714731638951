import React from "react";
import { GeneralErrorLayout, GeneralErrorText } from "./general-error-layout";
import { LinkActionButton } from "../button";
import treasureImage from "../../assets/treasure.svg";

export const NoAccountErrorLayout = () => (
  <GeneralErrorLayout
    image={treasureImage}
    title="Hi there! Thanks for your interest in Apiiro"
    description={
      <>
        <GeneralErrorText>
          Looks like you don’t have an Apiiro account yet.
        </GeneralErrorText>
        <GeneralErrorText>
          Please schedule a demo and we’ll contact you ASAP!
        </GeneralErrorText>
      </>
    }
  >
    <LinkActionButton href="https://apiiro.com/sign-up/">
      Schedule a demo
    </LinkActionButton>
  </GeneralErrorLayout>
);
