import { FC } from "react";
import styled from "styled-components";

export const GeneralError: FC = () => (
  <Container>
    It looks like Something went wrong, Please try again later
  </Container>
);

const Container = styled.div`
  font-weight: 300;
`;
