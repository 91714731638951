import React, { FC, ReactElement } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { GeneralError } from "./pages/general-error";
import { NoUser } from "./pages/no-user";
import { IdpUser } from "./pages/idp-user";
import { GlobalStyle } from "./style/Theme";
import styled from "styled-components/macro";
import backgroundImageUrl from "./assets/background.svg";
import { LoginLink } from "./components/link";
import { EnterpriseFirstConnector } from "./pages/enterprise-first-connector";
import { EnterpriseRegistration } from "./pages/enterprise-registration";
import { MarketplaceExistingUser } from "./pages/marketplace-existing-user";
import { MarketplaceRequestCreated } from "./pages/marketplace-request-created";
import { MarketplaceMissingEmail } from "./pages/marketplace-missing-email";
import cloudImage from "./assets/commercial-cloud.svg";
import { NoAccountErrorLayout } from "./components/layouts/no-account-error-layout";
import { ApiiroLogo } from "./components/layouts/general-error-layout";
import { SomethingWentWrongErrorLayout } from "./components/layouts/something-went-wrong-error-layout";
import { NoIdpUserErrorLayout } from "./components/layouts/no-idp-user-error-layout";

const App: FC = () => {
  const { pathname } = useLocation();

  if (
    pathname.includes("no-user") ||
    pathname.includes("error") ||
    pathname.includes("idp-user")
  ) {
    return (
      <PageContainer>
        <ApiiroLogo />
        <ContainerNewLayout>
          {pathname.includes("no-user") && <NoAccountErrorLayout />}
          {pathname.includes("idp-user") && <NoIdpUserErrorLayout />}
          {pathname.includes("error") && <SomethingWentWrongErrorLayout />}
        </ContainerNewLayout>
      </PageContainer>
    );
  }
  return (
    <PageContainer>
      <Container data-clean={pathname.includes("enterprise-registration")}>
        <GlobalStyle />
        <Routes>
          {errorRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<ErrorPage>{route.component}</ErrorPage>}
            />
          ))}
          <Route
            path="/enterprise-registration"
            element={<EnterpriseRegistration />}
          />
          <Route
            path="/marketplace-request-created"
            element={<MarketplaceRequestCreated />}
          />
          <Route path="*" element={<Navigate replace to="/error" />} />
        </Routes>
        <LoginLink />
      </Container>
    </PageContainer>
  );
};

const errorRoutes: { path: string; component: ReactElement }[] = [
  {
    path: "/error",
    component: <GeneralError />,
  },
  {
    path: "/no-user",
    component: <NoUser />,
  },
  {
    path: "/idp-user",
    component: <IdpUser />,
  },
  {
    path: "/marketplace-existing-user",
    component: <MarketplaceExistingUser />,
  },
  {
    path: "/marketplace-missing-email",
    component: <MarketplaceMissingEmail />,
  },
  {
    path: "/enterprise-first-connector",
    component: <EnterpriseFirstConnector />,
  },
];

const ErrorPage: FC = ({ children }) => (
  <ErrorDataContainer>
    <CloudImage />
    <h1>Hmm...</h1>
    {children}
  </ErrorDataContainer>
);

export default App;

const PageContainer = styled.div`
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Container = styled.div`
  width: 880px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${backgroundImageUrl});
  background-repeat: no-repeat;
  text-align: center;
  white-space: pre;
  gap: 12px;
  margin: 0 auto;

  &[data-clean="true"] {
    width: 100%;
    text-align: left;
    background-image: none;
  }
`;

const ErrorDataContainer = styled.div`
  width: 600px;
  height: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
`;

const CloudImage = styled.div`
  position: absolute;
  bottom: 140px;
  width: 226px;
  height: 200px;
  background-image: url(${cloudImage});
  background-repeat: no-repeat;
`;

const ContainerNewLayout = styled.div``;
