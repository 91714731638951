import { Link } from "../components/link";

export const EnterpriseFirstConnector = () => (
  <>
    <div>
      Seems like this is the first Apiiro installation on your GitHub server
    </div>
    <div>
      To use an App on a GitHub enterprise server you must first follow{" "}
      <Link href="https://docs.apiiro.com/integrations/pull-requests/Github-enterprise-pr-bot">
        this
      </Link>{" "}
      guide, and try again
    </div>
  </>
);
