import { FC } from "react";

export const IdpUser: FC = () => (
  <>
    <div>
      Sorry, but it seems like your organization is using custom authentication
      {"\n"}
      so you are not authorized to sign in using GitHub.
    </div>
    <div>Please go back to the login page and try again using your email.</div>
  </>
);
