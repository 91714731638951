import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Mulish', HelveticaNeue, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: lighter;
  }
  
  h1 {
    font-size: 58px;
    font-weight: 700;
    color: #00D9BE;
    margin: 0;
  }
`;
