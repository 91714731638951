import { FC } from "react";
import { Link } from "../components/link";

export const NoUser: FC = () => (
  <>
    <div>
      Thank you for your interest in Apiiro,{"\n"}but it seems like you don’t
      have an account with us yet.
    </div>
    <div>
      Please submit a trial request before signing up via &nbsp;
      <Link href="https://apiiro.com/sign-up/">apiiro.com</Link> (Start Free).
    </div>
  </>
);
