import styled from "styled-components/macro";
import { Link } from "./link";

const black = "#121212";

export const Button = styled.button<{ secondary?: boolean }>`
  font-size: 16px;
  font-family: "Mulish", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-weight: 600;
  color: ${({ secondary }) => (secondary ? black : "white")};
  background-color: ${({ secondary }) => (secondary ? "white" : black)};
  padding: 0 1rem;
  border-radius: 0.25rem;
  line-height: 2.5;
  border: 1px solid black;

  &:disabled {
    color: ${({ secondary }) => (secondary ? "#000000ab" : "white")};
    background-color: ${({ secondary }) => (secondary ? "white" : "#000000ab")};
    cursor: default;
    border: 1px solid #000000ab;
  }
`;

export const LinkActionButton = styled(Link)`
  display: flex;
  height: 32px;
  min-width: 76px;
  padding: 0px 12px 0px 12px;
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  background: #21263f;
  color: white;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    color: #e5e5e5;
  }
`;
