import { FC } from "react";
import styled from "styled-components";

export const LoginLink: FC = () => (
  <LoginLinkElement href="https://login.apiiro.com">
    Back to login page
  </LoginLinkElement>
);

export const Link = styled.a`
  color: #2e5a9e;

  &:hover {
    color: #25487e;
  }
`;

const LoginLinkElement = styled(Link)`
  position: absolute;
  bottom: 0;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
`;
