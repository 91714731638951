import styled from "styled-components/macro";
import { ReactNode } from "react";
import ApiiroLogoImage from "../../assets/apiiro-logo.svg";

export const GeneralErrorLayout = styled(
  ({
    image,
    title,
    description,
    children,
    ...props
  }: {
    image: any;
    title: JSX.Element | string;
    description: JSX.Element | string;
    children?: ReactNode;
  }) => (
    <div {...props}>
      <Container>
        <Image src={image} />
        <Heading2>{title}</Heading2>
        <Description>{description}</Description>
        {children}
      </Container>
    </div>
  )
)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #21263f;
  font-family: "mulish";
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: center;
  white-space: break-spaces;
  margin-bottom: 12px;
`;

export const GeneralErrorText = styled.span`
  text-align: center;
  white-space: break-spaces;
  font-size: 14px;
`;

const Image = styled.img`
  margin-bottom: 10px;
`;

export const ApiiroLogo = styled.div`
  width: 100px;
  height: 24px;
  position: absolute;
  top: 40px;
  left: 40px;
  background-image: url(${ApiiroLogoImage});
  background-repeat: no-repeat;
  background-size: contain;
`;

export const Heading2 = styled.h2`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 4px;
`;
