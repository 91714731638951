import { Link } from "../components/link";

export const MarketplaceExistingUser = () => (
  <>
    <div>Seems like you already have an environment.</div>
    <div>
      To install the GitHub application on an existing Apiiro environment,
      please follow{" "}
      <Link href="https://docs.apiiro.com/integrations/pull-requests/Githb-pr-bot">
        this guide
      </Link>
    </div>
  </>
);
