import React from "react";
import { GeneralErrorLayout, GeneralErrorText } from "./general-error-layout";
import bullsEyeImage from "../../assets/bulls-eye.svg";
import { LinkActionButton } from "../button";

export const NoIdpUserErrorLayout = () => (
  <GeneralErrorLayout
    image={bullsEyeImage}
    title="Oops! Something went wrong"
    description={
      <GeneralErrorText>
        Sorry, but it seems like your organization is using custom
        authentication,{"\n"} so you are not authorized to sign in using GitHub.
        {"\n"}Please go back to the login page and try again using your email.
      </GeneralErrorText>
    }
  >
    <LinkActionButton href="https://login.apiiro.com">
      Back to login
    </LinkActionButton>
  </GeneralErrorLayout>
);
