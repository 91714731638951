import styled from "styled-components/macro";
import doneImage from "../assets/commercial-done.svg";

export const MarketplaceRequestCreated = () => (
  <Container>
    <DoneImage />
    <h3>Almost there…</h3>
    Thank you for applying for an Apiiro free trial{"\n"}We will review your
    request and get back to you as soon as possible
  </Container>
);

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;

  h3 {
    font-size: 58px;
    font-weight: 700;
    color: #00d9be;
  }
`;

const DoneImage = styled.div`
  position: absolute;
  bottom: 80px;
  width: 226px;
  height: 200px;
  background-image: url(${doneImage});
  background-repeat: no-repeat;
`;
