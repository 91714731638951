import React from "react";
import { GeneralErrorLayout, GeneralErrorText } from "./general-error-layout";
import bullsEyeImage from "../../assets/bulls-eye.svg";
import { Link } from "../link";
import { LinkActionButton } from "../button";

export const SomethingWentWrongErrorLayout = () => (
  <GeneralErrorLayout
    image={bullsEyeImage}
    title="Oops! Something went wrong"
    description={
      <>
        <GeneralErrorText>You can refresh or try again later</GeneralErrorText>
        <GeneralErrorText>
          Having issues?{" "}
          <Link href="https://apiiro.atlassian.net/servicedesk/customer/portals">
            Contact our support
          </Link>
        </GeneralErrorText>
      </>
    }
  >
    <LinkActionButton href="https://login.apiiro.com">
      Back to login
    </LinkActionButton>
  </GeneralErrorLayout>
);
